// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import Header from './components/Header';
import NavigationGrid from './components/NavigationGrid';
import Footer from './components/Footer'; 


import AnalyticsDashboard from './pages/AnalyticsDashboard';
// Import other pages

function App() {
  return (
    <Router>
      <div className="d-flex flex-column min-vh-100">
        <Header />
        <main style={{ flex: '1' }}>
          <Routes>
          <Route path="/" element={<NavigationGrid />} />
            <Route path="/analytics" element={<AnalyticsDashboard />} />
            {/* Define other routes */}
          </Routes>
        </main>
        <Footer /> 
      </div>
    </Router>
  );
}

export default App;
