// components/Footer.js
import React from 'react';

const Footer = () => {
    return (
        <footer className="py-4 bg-light mt-auto">
            <div className="container">
                <p className="m-0 text-center text-muted">© 2024 EdoData | Your one-stop hub for educational data and insights.</p>
            </div>
        </footer>
    );
};

export default Footer;
