// NavigationGrid.js
import React from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const navigationItems = [
    { id: 1, name: "Analytics Dashboard", icon: "📊", description: "Dive into data.", link: "/analytics" },
    { id: 2, name: "Placeholder", icon: "🔗", description: "Placeholder description.", link: "/placeholder2" },
    { id: 3, name: "Placeholder", icon: "🔗", description: "Placeholder description.", link: "/placeholder3" },
    { id: 4, name: "Placeholder", icon: "🔗", description: "Placeholder description.", link: "/placeholder4" },
    { id: 5, name: "Placeholder", icon: "🔗", description: "Placeholder description.", link: "/placeholder5" },
    { id: 6, name: "Placeholder", icon: "🔗", description: "Placeholder description.", link: "/placeholder6" },
    { id: 7, name: "Placeholder", icon: "🔗", description: "Placeholder description.", link: "/placeholder7" },
    { id: 8, name: "Placeholder", icon: "🔗", description: "Placeholder description.", link: "/placeholder8" },
    { id: 9, name: "Placeholder", icon: "🔗", description: "Placeholder description.", link: "/placeholder9" },
];

const NavigationGrid = () => {
    return (
        <Container>
            <Row xs={1} md={3} className="g-4">
                {navigationItems.map((item) => (
                    <Col key={item.id}>
                        <Link to={item.link} className="text-decoration-none">
                            <Card className="h-100 text-center">
                                <Card.Body>
                                    <div className="icon" style={{fontSize: '2rem'}}>{item.icon}</div>
                                    <Card.Title>{item.name}</Card.Title>
                                    <Card.Text>{item.description}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Link>
                    </Col>
                ))}
            </Row>
        </Container>
    );
};


export default NavigationGrid;

