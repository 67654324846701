// Header.js
import React from 'react';

const Header = () => {
    return (
        <header className="py-5 bg-light border-bottom mb-4">
            <div className="container">
                <div className="text-center my-5">
                    <h1>EdoData 🤓</h1>
                </div>
            </div>
        </header>
    );
};

export default Header;
